import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../firebase/config';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { Dialog, DialogContent, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import ClosableDialogTitle from '../generic/ClosableDialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';


const SignInDialog = (props) => {
  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // // The signed-in user info.
        // const user = result.user;
      }).catch((error) => {
        // // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }

  return <Dialog open={props.open} onClose={props.onClose} >
    <ClosableDialogTitle onClose={props.onClose}>{t('signin', {ns: 'auth'})}</ClosableDialogTitle>
    <DialogContent dividers>
      <List component='nav'>
        {user ?
          <ListItem>
            <ListItemText primary={t('already_signedin', {ns: 'auth'})} />
          </ListItem>
        :
          <ListItem button onClick={signInWithGoogle}>
            <ListItemIcon><FontAwesomeIcon icon={['fab', 'google']} /></ListItemIcon>
            <ListItemText primary={t('with_google', {ns: 'auth'})} />
          </ListItem>
        }
      </List>
    </DialogContent>
  </Dialog>
}

export default SignInDialog;
