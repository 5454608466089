import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { styled } from '@mui/system';

// Match with the useDownload URL API:
// https://github.com/CSFrequency/react-firebase-hooks/tree/master/storage#usedownloadurl

const Image = ({src, loading, error, title='image', sx}) => {
  return <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...sx
  }}>
    {error &&
      <FontAwesomeIcon icon={['fas', 'exclamation']} />}
    {loading &&
      <FontAwesomeIcon icon={['fas', 'spinner']} spin />}
    {!loading && src && (
      <Img draggable="false" src={src} alt={title} />
    )}
  </Box>
}

const Img = styled('img')({
  maxHeight: '100%',
  maxWidth: '100%',
  display: 'block',
  width: 'auto',
  height: 'auto',
  WebkitTouchCallout: 'none'
});

export default Image;