import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { keyframes } from "@mui/system";

// ref. https://codepen.io/alexitaylor/pen/RgxJwg

const Neon = ({ text = "Neon", color = "#2F5DDB", variant="h3" }) => {
  const animateNeon = keyframes`
    from {
      text-shadow: 0 0 10px #fff,
        0 0 20px #fff,
        0 0 30px #fff,
        0 0 40px ${color},
        0 0 70px ${color},
        0 0 80px ${color},
        0 0 100px ${color},
        0 0 150px ${color};
    }
    to {
      text-shadow: 0 0 5px #fff,
        0 0 10px #fff,
        0 0 15px #fff,
        0 0 20px ${color},
        0 0 35px ${color},
        0 0 40px ${color},
        0 0 50px ${color},
        0 0 75px ${color};
    }
  `;

  return (
    <Typography
      variant={variant}
      sx={{
        color: "white",
        marginBottom: "1rem",
        // fontFamily: "Monoton",
        animation: `${animateNeon} 1.5s ease-in-out infinite alternate`,
      }}
    >
      {text}
    </Typography>
  );
};

Neon.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default Neon;
