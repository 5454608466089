import { Container, Typography } from "@mui/material";
import Documents from "./panels/Documents";
import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import Storage from "../firebase/Storage";

import { useTranslation } from 'react-i18next';


const DocumentsContainer = (props) => {
  const history = useNavigate();
  const onLoadDocument = useCallback((type, id) => history('/factory?' + Storage.typeString(type) + '=' + id), [history]);
  const { t } = useTranslation();

  return <Container>
    <Typography variant='h1'>{t('load', {ns: 'keywords'})}</Typography>
    <Typography variant='h3'>{t('machine', {ns: 'keywords', 'count': 100})}</Typography>
    <Documents type={Storage.TYPE.MACHINE} onLoad={onLoadDocument}/>
    <Typography variant='h3'>{t('level', {ns: 'keywords', 'count': 100})}</Typography>
    <Documents type={Storage.TYPE.LEVEL} onLoad={onLoadDocument}/>
  </Container>
};

export default DocumentsContainer;
