import React from "react";
import PropTypes from "prop-types";
import { keyframes } from "@mui/system";
import { Box } from "@mui/material";
import DynamicParticle from "./DynamicParticle";

// ref.
// gradient: https://www.gradient-animator.com/

const DynamicBackground = ({
    colors = ["#1E1E1E","#323232","#373737"],
    gradientAngle = 110,
    scrollAngle = 45,
    speed = 30,
    nParticles = 1,
    children
  }) => {
  const gradientColors = colors.join(", ");

  const [startX, startY, endX, endY] = scrollAngleToAnimation(scrollAngle);
  const animateGradient = keyframes`
    0% { background-position: ${startX}% ${startY}%; }
    50% { background-position: ${endX}% ${endY}%; }
    100% { background-position: ${startX}% ${startY}%; }
  `;

  const backgroundStyle = {
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: 'hidden',
    background: `linear-gradient(${gradientAngle}deg, ${gradientColors})`,
    backgroundSize: "800%",
    paddingTop: "1em",
    paddingBottom: "1em",
    animation: `${animateGradient} ${speed}s ease infinite`,
  };

  const particles = Array.from({ length: nParticles }, (_, index) => (
    <DynamicParticle
      key={index}
    />
  ));

  return <Box sx={backgroundStyle}>
    <Box sx={{
      zIndex: 1
    }}>
      {children}
    </Box>
    {particles}
  </Box>;
};

DynamicBackground.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),

  gradientAngle: PropTypes.number,
  scrollAngle: PropTypes.number,
  speed: PropTypes.number,

  nParticles: PropTypes.number,

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

function scrollAngleToAnimation(a) {
  let startX, startY, endX, endY;
  const scrollAngle = mod(360, a);
	if ((scrollAngle < 45) || ((135 < scrollAngle) && (scrollAngle < 225)) || (315 < scrollAngle)) {
		const mathStuff = Math.tan(toRadians(180-scrollAngle));
		startX 	= 0;
		startY 	= 100 - parseInt((50 * mathStuff) + 50);
		endX	= 100;
		endY 	= 100 - parseInt(50 - (50 * mathStuff));
	}
	else {
		const mathStuff = Math.tan(toRadians(scrollAngle-90));
		startX 	= 100 - parseInt((50 * mathStuff) + 50);
		startY 	= 0;
		endX 	= 100 - parseInt(50 - (50 * mathStuff));
		endY 	= 100;
	}

	startX 	= Math.min(100, startX); 
	startY 	= Math.min(100, startY);
	endX 	= Math.min(100, endX); 
	endY 	= Math.min(100, endY);

  return [startX, startY, endX, endY];
}

function toRadians (angle) {
  return angle * (Math.PI / 180);
}

function mod(n, m) {
  return ((m % n) + n) % n;
}

export default DynamicBackground;
