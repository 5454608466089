import React from "react";
import { Box } from "@mui/material";
import { keyframes } from "@mui/system";

// ref: https://dev.to/arindam1997007/designing-a-stunning-floating-blob-in-the-background-a-step-by-step-guide-3joa
// ref: https://9elements.github.io/fancy-border-radius/

const calculateTranslation = () => {
  return `translate(${Math.random() * 80 + 10}%, ${Math.random() * 80 + 10}%)`
};

const calculateBorderRadius = () => {
  const b = Array.from({ length: 4 }, () => Math.random() * 100 );
  return `${b[0]}% ${100 - b[0]}% ${b[1]}% ${100 - b[1]}% / ${b[2]}% ${b[3]}% ${100 - b[3]}% ${100 - b[2]}%`;
};

const moveAnimation = (angle, distance) => keyframes`
  0% {
    transform: ${calculateTranslation()};
    border-radius: ${calculateBorderRadius()};
  }
  50% {
    transform: ${calculateTranslation()};
    border-radius: ${calculateBorderRadius()};
    rotate: 41deg;
    scale: 1.2;
  }
  100% {
    transform: ${calculateTranslation()};
    border-radius: ${calculateBorderRadius()};
    rotate: -60deg;
    scale: 0.8;
  }
`;

const DynamicParticle = (props) => {
  const sizeMean = 600;
  const blur = 30;
  const animationDurationMean = 50;
  const animationDuration = 1000 * animationDurationMean + (2 * Math.random() - 0.5) * 0.1 * animationDurationMean * 1000;
  const size = sizeMean + (2 * Math.random() - 0.5) * 0.5 * sizeMean;

  const particleStyle = {
    width: size,
    height: size,
    background: "linear-gradient(47deg, rgba(88, 255, 139,1) 21%, rgb(0 164 173) 67%, rgba(74,74,200,1) 81%)",
    borderRadius: calculateBorderRadius(),
    opacity: "0.2",
    filter: `blur(${blur}px)`,
    position: "absolute",
    animation: `${moveAnimation()} ${animationDuration}ms cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite alternate forwards`,
  };

  return <Box sx={{ ...particleStyle }} />;
};

DynamicParticle.propTypes = {
};

export default DynamicParticle;
