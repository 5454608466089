import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const YoutubeEmbed = ({ embedId, title }) => (
  <Box sx={{
    width: '80%',
    paddingBottom: '50%',
    position: 'relative',
    '> iframe': {
        left: 0,
        top: 0,
        width: '100%',
        aspectRatio: '16 / 9',
        position: 'absolute',
      }
  }}>
    <iframe
      src={"https://www.youtube.com/embed/" + embedId}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  </Box>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
