import { Container } from "@mui/material";

const PageContainer = ({children}) => {
  return (
    <Container sx={{marginTop: "3rem", marginBottom: "3rem"}}>
      {children}
    </Container>
  );
};

PageContainer.propTypes = {
};

export default PageContainer;
