import React from "react";
import PropTypes from "prop-types";

function isSafari() {
  var safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return safari;
}

function isWebMSupported() {
  if (isSafari())
    return false;
  let webm = false;
  let testEl = document.createElement("video");
  if (testEl.canPlayType)
    webm = "" !== testEl.canPlayType('video/webm; codecs="vp9, vorbis"');
  testEl.remove();
  return webm;
}

const WebM = ({ name, width, height }) => {
  if (!isWebMSupported())
    return (
      <img
        src={`/static/movies/${name}.fallback.png`}
        alt="fallback"
        width={width}
        height={height}
      />
    );

  return (
    <video
      style={{
        outline: "none",
        width: width + "px",
        height: height + "px",
      }}
      width={width + "px"}
      height={height + "px"}
      playsInline
      autoPlay
      muted
      loop
    >
      <source src={`/static/movies/${name}.webm`} type="video/webm" />
    </video>
  );
};

WebM.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default WebM;
