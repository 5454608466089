// import PropTypes from 'prop-types';

import { Box, Stack } from "@mui/material";
import Footer from "./Footer";

const Page = (props) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      spacing={0}
      sx={{
        minHeight: '100vh'
      }}
    >
      {/* TODO: add topbar here */}
      <Box sx={{width: '100%'}}>
        {props.children}
      </Box>
      <Footer />
    </Stack>
  );
};

Page.propTypes = {
  //   simulationTheme: PropTypes.bool.isRequired,
};

export default Page;
