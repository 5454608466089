import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const StaticBackground = ({ colors = ["#1F1F1F","#2F2F2F","#292929"], gradientAngle = 110, children }) => {
  const gradientColors = colors.join(",");

  const backgroundStyle = {
    width: "100%",
    background: `linear-gradient(${gradientAngle}deg, ${gradientColors})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    paddingTop: "1em",
    paddingBottom: "1em",
    position: 'relative',
    overflow: 'hidden',
  };

  return <Box sx={backgroundStyle}>{children}</Box>;
};

StaticBackground.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  gradientAngle: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default StaticBackground;
