const worlds = [
  {
    'title': 'Discover - Parcours',
    'slug': 'discover-parcours',
    'description': 'Your first steps in the game. Discover how to move the character and drive simple machines.',
    'levels': [
      {
        'title': 'First Journey',
        'description': 'Can you bring down the green flag?',
        'slug': 'first-journey'
      },
      {
        'title': 'Car Journey',
        'description': 'Can you bring down the green flag?',
        'slug': 'car-journey'
      },
      {
        'title': 'Sky Lifter',
        'description': 'Can you find the button to complete the level?',
        'slug': 'sky-lifter'
      }
    ]
  },
  {
    'title': 'Discover - Machine Build',
    'slug': 'discover-machine-build',
    'description': 'Your first steps in the game. Discover how to create simple machines.',
    'levels': [
      {
        'title': 'Chain Reaction',
        'description': 'Could you create a chain reaction and bring down the domino towers?',
        'slug': 'chain-reaction'
      },
      {
        'title': 'Roll down the Slope',
        'description': "A box doesn't roll well. Can you bring down the orange block down the slope?",
        'slug': 'roll-down-the-slope'
      },
      {
        'title': 'Bridge',
        'description': "Can you create a bridge to let the vehicle pass the cliff?",
        'slug': 'pass-the-bridge'
      }
    ]
  }
]

export default class Universe {
  static get worlds() {
    return worlds;
  }

  static getNextLevel(slug) {
    for (let w = 0; w < worlds.length; w++) {
      let world = worlds[w];
      for (let l = 0; l < world.levels.length; l++) {
        let lvl = world.levels[l];
        if (lvl.slug === slug) {
          if ((l + 1) < world.levels.length)
            return world.levels[l + 1].slug;
          else if ((w + 1) < worlds.length && worlds[w + 1].levels.length > 0)
            return worlds[w + 1].levels[0].slug;
          else
            return undefined;
        }
      }  
    }
  }

  static getTitle(slug) {
    return Universe._retrieveLevelFromSlug(slug)?.title;
  }

  static getDescription(slug) {
    return Universe._retrieveLevelFromSlug(slug)?.description;
  }

  static _retrieveLevelFromSlug(slug) {
    for (let w = 0; w < worlds.length; w++) {
      let world = worlds[w];
      for (let l = 0; l < world.levels.length; l++) {
        let lvl = world.levels[l];
        if (lvl.slug === slug)
          return lvl;
      }  
    }
  }
}
