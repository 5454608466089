import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTitle, IconButton } from '@mui/material';

const ClosableDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          userSelect: 'none'
        }}
        {...other}
      >
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              color: (theme) => theme.palette.grey[500],
              m: 0,
              pt: 0.5, pr: 2,
              right: 0
            }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  export default ClosableDialogTitle;
