import PropTypes from "prop-types";
import React, { useState } from "react";
import Lottie from "react-lottie-player";
import logoAnimation from "../../animations/logo";

const loopFrame = 34;

const MBGLogo = ({ height }) => {
  const [firstStageCompleted, setFirstStageCompleted] = useState(false);

  const handleLoopComplete = () => {
    setFirstStageCompleted(true);
  };

  const segments = firstStageCompleted ?
    [loopFrame, logoAnimation.op - 1] :
    [0, loopFrame];
  return (
    <Lottie
      loop={firstStageCompleted}
      play={true}
      style={{
        height: height,
        filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))"
      }}
      animationData={logoAnimation}
      onComplete={handleLoopComplete}
      segments={segments}
    />
  );
};

MBGLogo.propTypes = {
  height: PropTypes.string.isRequired,
};

export default MBGLogo;
