import React from "react";

import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import DynamicBackground from "../generic/DynamicBackground";
import PageContainer from "../PageContainer";
import Neon from "../generic/Neon";
import WebM from "../generic/WebM";

const NotFoundPage = (props) => {
  const { t } = useTranslation();

  return (
    <Page>
      <DynamicBackground>
        <PageContainer>
          <Neon text={t("title", { ns: "page_not_found" })} />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
            sx={{
              padding: 3,
            }}
          >
            <WebM name="idle" width={116} height={200} />
            <Stack
              spacing={2}
            >
              <Typography>
                {t("description", { ns: "page_not_found" })}
              </Typography>
              <Button color="inherit" variant="contained" href="/">
                {t("return", { ns: "page_not_found" })}
              </Button>
            </Stack>
          </Stack>
        </PageContainer>
      </DynamicBackground>
    </Page>
  );
};

export default NotFoundPage;
