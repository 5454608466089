// import PropTypes from "prop-types";

import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const languages = {
  en: { nativeName: "English" },
  fr: { nativeName: "Français" },
};

const LanguageButton = (props) => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <Button
        sx={{ color: 'text.primary' }}
        variant="text"
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={['fas', 'language']} size="lg"/>
      </Button>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(languages).map((language) => (
          <MenuItem
            key={language}
            color="inherit"
            variant="text"
            disabled={i18n.resolvedLanguage === language || (language === 'en' && i18n.resolvedLanguage === undefined)}
            onClick={() => {
              setLanguage(language);
            }}
          >
            {languages[language].nativeName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

LanguageButton.propTypes = {
  //   simulationTheme: PropTypes.bool.isRequired,
};

export default LanguageButton;
