import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@mui/material";

import { t } from "i18next";

const StartupCard = ({
  title,
  description,
  image,
  url,
  cover = true,
  animateImage = false,
}) => {
  return (
    <Card
      sx={{
        m: 0.5,
        maxWidth: 345,
        overflow: animateImage ? "visible" : "hidden",
        transition: "all .2s ease-in-out",
        '&:hover': {
          transform: animateImage ? "scale(1.1)" : "none"
        }
    }}
    >
      <CardMedia
        sx={{
          height: 140,
          backgroundSize: cover ? "cover" : "contain",
          transform: "scale(1.3)"
        }}
        image={image}
        title={title}
      />
      <CardHeader title={title} />
      <CardContent>{description}</CardContent>
      {url && (
        <CardActions>
          <Button href={url}>{t("load", { ns: "keywords" })}</Button>
        </CardActions>
      )}
    </Card>
  );
};

StartupCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  cover: PropTypes.bool,
};

export default StartupCard;
