import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import { useQueryParam, StringParam } from 'use-query-params';
import { Backdrop, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

import { isMobile } from 'react-device-detect';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { useSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";

import { auth } from './firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';

import Restricted from './components/pages/Restricted';

// Note: Lazy loading of the Game:
// https://reactjs.org/docs/code-splitting.html#reactlazy
// import Game from './components/Game';
const Game = React.lazy(() => import('./components/Game'));

const GameContainer = (props) => {
  const [user] = useAuthState(auth);

  const [levelID, setLevelID] = useQueryParam('level', StringParam);
  const [generateThumbnails] = useQueryParam('generateThumbnails', StringParam);
  const { enqueueSnackbar } = useSnackbar()
  const { i18n } = useTranslation();

  if (user === null)
    return <Restricted />

  return <Box sx={{
      maxWidth: '100vw',
      width: '100vw',
      height: '100dvh',
      maxHeight: '100vh', // fallback in case dvh unit is not available
      p: 0,
      userSelect: 'none'
    }}>
    <Suspense fallback={
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    }>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Game
          config={{
            isLevelEditor: props.isLevelEditor,
            isMobile: isMobile,
            locale: i18n.resolvedLanguage === undefined ? 'en' : i18n.resolvedLanguage
          }}

          levelID={levelID}
          setLevelID={setLevelID}
          generateThumbnails={generateThumbnails}

          setSimulationTheme={props.setSimulationTheme}

          enqueueSnackbar={enqueueSnackbar}
        />
      </DndProvider>
    </Suspense>
  </Box>
}

GameContainer.propTypes = {
  isLevelEditor: PropTypes.bool.isRequired,
  setSimulationTheme: PropTypes.func.isRequired,
}

export default GameContainer;
