import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import App from "./App";

import "./index.css";
import { Backdrop, CircularProgress } from "@mui/material";

// Strict mode would be nice, but causes lot of issues in development because of the double
// initialization of the components, and its bad support with Ammo and Babylonjs.
// import { StrictMode } from 'react';

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <StrictMode>
  <Suspense
    fallback={
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    }
  >
    <App />
  </Suspense>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
