import { Box, Container, Typography } from '@mui/material';
import Universe from './universe';

import Storage from '../../firebase/Storage';
import StartupCard from './StartupCard';

const Worlds = (props) => {
  return <>
    {Universe.worlds.map((world, i) =>
      <World key={world.slug} world={world} worldID={i + 1} />
    )}
  </>
}

const World = ({world, worldID}) => {
  return <Container>
    <Typography variant='h4'>World {worldID}: {world.title}</Typography>
    <Box display='flex' flexWrap='wrap' justifyContent='center' alignItems='center'>
      {world.levels.map((level, i) =>
        <StartupCard
          key={level.slug}
          title={level.title}
          description={level.description}
          image={'/static/worlds/' + level.slug + '.png'}
          url={'/factory?' + Storage.typeString(Storage.TYPE.LEVEL) + '=' + level.slug} />
      )}
    </Box>
  </Container>
}

export default Worlds;