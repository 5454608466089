import { useState } from 'react';
import PropTypes from 'prop-types';

import { AppBar, Button, ButtonGroup, IconButton, Menu, MenuItem, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { auth } from '../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';

import SignInDialog from './panels/SignInDialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageButton from './misc/LanguageButton';

import { useTranslation } from 'react-i18next';
import MBGLogo from './misc/MBGLogo';


const TopBar = (props) => {
  const [user] = useAuthState(auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSignIn, setShowSignIn] = useState(false);
  const { t } = useTranslation();

  const theme = useTheme();
  const wideScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <AppBar position='static'>
    <Toolbar sx={{
      justifyContent: 'space-between',
      bgcolor: props.simulationTheme ? 'primary.main' : 'background.paper'
    }}>
      <Button color='inherit' href='/' sx={{ fontSize: '125%' }}>
        <MBGLogo height="2em" />
      </Button>
      { wideScreen ?
        <ButtonGroup>
          <Button color='inherit' variant='text' href='/about'>{t('about', {ns: 'menu'})}</Button>
          <LanguageButton />
          { user ?
            <Button color='inherit' variant='text' onClick={() => auth.signOut()}>{t('logout', {ns: 'auth'})}</Button>
          :
            <Button color='inherit' variant='text' onClick={() => {setShowSignIn(true)}}>{t('signin', {ns: 'auth'})}</Button>
          }
        </ButtonGroup>
      :
        <>
          <IconButton
            color='inherit'
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={['fas', 'bars']} />
          </IconButton>
        </>
      }
    </Toolbar>

    <SignInDialog open={!user && showSignIn} onClose={() => {setShowSignIn(false)}}  />

    { !wideScreen &&
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem color='inherit' variant='text' href='/about' onClick={handleClose}>{t('about', {ns: 'menu'})}</MenuItem>
        <LanguageButton />
        { user ?
          <MenuItem color='inherit' variant='text' onClick={() => {auth.signOut(); handleClose()}}>{t('logout', {ns: 'auth'})}</MenuItem>
        :
          <MenuItem color='inherit' variant='text' onClick={() => {setShowSignIn(true); handleClose()}}>{t('signin', {ns: 'auth'})}</MenuItem>
        }
      </Menu>
    }
  </AppBar>
}

TopBar.propTypes = {
  simulationTheme: PropTypes.bool.isRequired,
}

export default TopBar;
