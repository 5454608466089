import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const SocialButton = ({ url, iconName, large=false }) => {
  return (
    <Button
      component={Link}
      to={url}
      target="_blank"
      rel="noopener noreferrer"
      variant="text"
      sx={{ minWidth: "auto" }}
    >
      <FontAwesomeIcon
        icon={["fab", iconName]}
        size={large ? "3x" : "lg" }
      />
    </Button>
  );
};

export default SocialButton;
