import React from "react";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "../Page";
import DynamicBackground from "../generic/DynamicBackground";
import PageContainer from "../PageContainer";
import Neon from "../generic/Neon";

const Restricted = (props) => {
  const { t } = useTranslation();

  return (
    <Page>
      <DynamicBackground>
        <PageContainer>
          <Neon text={t("restricted", { ns: "auth" })} />
          <Typography>{t('must_be_signedin', {ns: 'auth'})}</Typography>
        </PageContainer>
      </DynamicBackground>
    </Page>
  );
};

export default Restricted;
