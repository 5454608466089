import React from 'react';

import { Box, Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';

import { firestore, storage } from '../../firebase/config';
import { collection, query, orderBy, limit } from "firebase/firestore";
import { ref } from "firebase/storage";
import PropTypes from 'prop-types';

import { useCollection } from 'react-firebase-hooks/firestore';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import Image from '../generic/Image';

import { t } from 'i18next';


const Documents = (props) => {
  const q = query(collection(firestore, props.type), orderBy('createdAt', 'desc'), limit(100));
  const [value, loading, error] = useCollection(q);

  return <Box
    display='flex'
    flexWrap='wrap'
    justifyContent='center'
    alignItems='center'
  >
    {error && <strong>Error: {JSON.stringify(error)}</strong>}
    {loading && <span>Collection: Loading...</span>}
    {value && (
      <>
        {value.docs.map((doc) => (
           <Document key={doc.id} id={doc.id} type={props.type} document={doc.data()} onLoad={props.onLoad} onDelete={props.onDelete}/>
        ))}
      </>
    )}
  </Box>
}

Documents.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,

  onLoad: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

const Document = ({id, type, document, onLoad, onDelete}) => {
  const screenshotRef = 'screenshots/' + id + '.jpg';
  // eslint-disable-next-line no-unused-vars
  const [imageURL, loading, error] = useDownloadURL(ref(storage, screenshotRef));

  return <Card sx={{ m: 0.5, minWidth: 200 }}>
    <CardMedia>
      <Image
        src={imageURL}
        loading={loading}
        error={error}
      />
    </CardMedia>
    <CardContent>
      <h1>{document.name}</h1>
      <p>{document.description}</p>
    </CardContent>
    <CardActions>
      {onLoad &&
        <Button size='small' variant='contained' onClick={() => onLoad(type, id)}>
          {t('load', {ns: 'keywords'})}
        </Button>
      }
      {onDelete &&
        <Button size='small' color='error' variant='contained' onClick={() => onDelete(type, id)}>
          {t('delete', {ns: 'keywords'})}
        </Button>
      }
    </CardActions>
  </Card>
};

export default Documents;