// import PropTypes from 'prop-types';

import { Container, Stack, Typography } from "@mui/material";
import MBGLogo from "./misc/MBGLogo";
import StaticBackground from "./generic/StaticBackground";
import { useTranslation } from "react-i18next";
import SocialButton from "../misc/SocialButton";


const Footer = (props) => {
  const { t } = useTranslation();

  return (
    <StaticBackground colors={["#161616", "#222222"]}>
      <Container>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <MBGLogo height="2rem" />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0}
          >
            <Typography sx={{ color: "grey" }}>{t("followUs", { ns: "footer" })}</Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <SocialButton url="https://discord.gg/sYVnUDU8TP" iconName="discord" />
              <SocialButton url="https://www.youtube.com/channel/UCuvoRPioD9uO0QaqQqEmZnw" iconName="youtube" />
              <SocialButton url="https://www.instagram.com/machine.building.game" iconName="instagram" />
              <SocialButton url="https://www.tiktok.com/@machine.building.game" iconName="tiktok" />
              <SocialButton url="https://www.facebook.com/people/Machine-Building-Game/61558840641221/" iconName="facebook" />
              <SocialButton url="https://twitter.com/_m_b_g" iconName="x-twitter" />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={0}
            sx={{
              userSelect: 'none'
            }}
          >
            <Typography sx={{ color: "grey" }} variant="caption">
              Copyright © 2024
            </Typography>
            <Typography sx={{ color: "grey" }} variant="caption">
              Machine Building Game
            </Typography>
            <Typography sx={{ color: "grey" }} variant="caption">
              {t("allRightsReserved", { ns: "footer" })}
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </StaticBackground>
  );
};

Footer.propTypes = {
  //   simulationTheme: PropTypes.bool.isRequired,
};

export default Footer;
