import { useEffect, useState } from 'react';

import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { useTranslation } from "react-i18next";

import { SnackbarProvider } from 'notistack';

import About from './components/pages/About';
import GameContainer from './GameContainer';
import HomePage from './components/Homepage';
import TopBar from './components/TopBar';
import DocumentsContainer from './components/DocumentsContainer';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import initializeIcons from './icons';
import { CssBaseline } from '@mui/material';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import TmpHomePage from './components/TmpHomePage';

import {Helmet} from "react-helmet";

import ReactGA from 'react-ga4';
import NotFoundPage from './components/pages/NotFoundPage';
ReactGA.initialize('G-ZZLMLSXH05');

initializeIcons();

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    ns: [
      'titles',

      'keywords',
      'menu',
      'auth',
      'footer',

      'page_about',
      'page_home',
      'page_not_found',
      'status',
 
      'snackbar',
  
      'enums',
      'blocks',
      'block_tooltips',
      'parameters',
      'parameter_tooltips',

      'game_messages',
      'game_menus',
      'game_tooltips',

      'blockly_categories',
      'blockly_enums',
      'blockly_messages',
      'blockly_tooltips',
    ],
    fallbackLng: "en",
    initImmediate: false,
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  const [simulationTheme, setSimulationTheme] = useState(false);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send("pageview");
  }, []);
  const { t } = useTranslation();

  const theme = createTheme({
    palette: {
      // https://mui.com/customization/color/#color-palette
      // https://mui.com/material-ui/customization/default-theme/
      primary: {
        main: simulationTheme ? '#EBDA99' : '#6EC2BB',
      },
      // background: {
      //   paper: "#0e052b",
      //   default: "#0e052b"
      // },
      mode: 'dark',
      yellow: '#E5D712'
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            overflowY: 'initial' // to fix visibility of sliders value tooltip if this is the first item of the fields.
          }
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            overflow: 'visible' // to fix visibility of sliders value tooltip if this is the first item of the fields.
          }
        }
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(3px)',
            zIndex: 3
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(3px)',
            zIndex: 2
          }
        }
      }
    }
  });

  return <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          <Routes>
            <Route path='/factory' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })} - {t("factory", { ns: "titles" })}</title></Helmet>
                <GameContainer isLevelEditor={false} setSimulationTheme={setSimulationTheme} />
              </>
            } />
            <Route path='/editor' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })} - {t("editor", { ns: "titles" })}</title></Helmet>
                <GameContainer isLevelEditor={true} setSimulationTheme={setSimulationTheme} />
              </>
            } />
            <Route path='/load' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })} - {t("load", { ns: "titles" })}</title></Helmet>
                <TopBar simulationTheme={simulationTheme} />
                <DocumentsContainer />
              </>
            } />
            <Route path='/about' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })} - {t("about", { ns: "titles" })}</title></Helmet>
                <TopBar simulationTheme={simulationTheme} />
                <About />
              </>
            } />
            <Route path='/home' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })}</title></Helmet>
                <TopBar simulationTheme={simulationTheme} />
                <HomePage />
              </>
            } />
            <Route path='/' element={
              <>
                <Helmet><title>{t("game", { ns: "titles" })}</title></Helmet>
                <TmpHomePage />
              </>
            } />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </SnackbarProvider>
      </QueryParamProvider>
    </ThemeProvider>
  </BrowserRouter>
}

export default App;